import React, { ReactElement } from 'react';
import './Rules.scss';
import Et from './Et';
import En from './En';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const Rules: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  let RuleSet = Et;
  switch (getLanguage()) {
    case 'et':
      RuleSet = Et;
      break;
    case 'en':
      RuleSet = En;
      break;
    default:
      RuleSet = Et;
      break;
  }

  return (
    <div className="rules columns">
      <div className="column">
        <RuleSet />
      </div>
    </div>
  );
};

export default Rules;
